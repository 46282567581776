<template>
  <main class="user-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card class="card-border card-round">
              <div class="card-media">
                <mega-image ratio="1x1" :src="avatar" v-if="!avatar" />
                <img class="w-100" :src="avatar" v-if="avatar" alt="" />
              </div>

              <nav class="nav flex-column font-small text-muted">
                <div class="nav-text">
                  <i class="i-user-plus"></i>
                  <span>{{ user["a_time"] | u2d("DD.MM.Y") }}</span>
                </div>

                <div class="nav-text">
                  <i class="i-gift"></i>
                  <span>{{ user["birthday_date"] || "- - -" }}</span>
                </div>

                <a
                  :href="'tel:' + user.phone"
                  class="nav-link"
                  :class="{ 'events-disable text-dark': !user.phone }"
                >
                  <i class="i-phone"></i>
                  <span>{{ user.phone || "- - -" }}</span>
                </a>

                <a
                  :href="'mailto:' + user.email"
                  class="nav-link"
                  :class="{ 'events-disable text-dark': !user.email }"
                >
                  <i class="i-mail"></i>
                  <span>{{ user.email || "- - -" }}</span>
                </a>
              </nav>
            </mega-card>

            <mega-card>
              <ul class="menu-list card-body">
                <li class="menu-item d-flex flex-column">
                  <mega-switch
                    class="w-100 bg-success"
                    :label="$t('influencer')"
                    @click="influencerToggle"
                    :checked="user.is_influencer"
                  />
                  <mega-switch
                    class="w-100 bg-success"
                    :label="$t('evangelist')"
                    @click="evangelistToggle"
                    :checked="user.is_evangelist"
                  />
                  <mega-switch
                    class="w-100 bg-success"
                    :label="$t('producer')"
                    @click="producerToggle"
                    :checked="user.is_producer"
                  />
                  <mega-select
                    class="w-100 mb-0"
                    :label="$t('vip_status')"
                    :placeholder="$t('vip_status_holder')"
                    :options="[
                      { value: 1, name: $t('no_vip') },
                      { value: 2, name: $t('vip') },
                      { value: 3, name: $t('vip_pro') }
                    ]"
                    v-model="vipStatus"
                  />
                </li>
              </ul>
            </mega-card>

            <qr-code :uri="'type=prof&id=' + id" />
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card class="mb-5">
              <div class="card-body d-flex flex-column">
                <div
                  class="d-flex flex-row justify-content-between align-items-center"
                >
                  <div class="w-100">
                    {{ user.name }}
                    <IconBase
                      v-if="user.verified"
                      viewBox="0 0 312 311"
                      iconColor="#45C1C9"
                      ><UserVerified
                    /></IconBase>
                  </div>
                  <div class="d-flex flex-row align-items-center">
                    <div class="mr-3 d-flex flex-row align-items-center">
                      {{ $t("user_verified") }}
                      <IconBase
                        class="ml-2"
                        viewBox="0 0 312 311"
                        iconColor="#45C1C9"
                        ><UserVerified
                      /></IconBase>
                    </div>
                    <mega-switch
                      class="m-0 d-flex flex-row align-items-center"
                      @click="verifyToggle"
                      :checked="user.verified"
                    />
                  </div>
                </div>

                <div>
                  <strong>{{ $t("sex") }}:</strong> {{ user["sex"] }}
                </div>
              </div>
            </mega-card>

            <mega-card
              v-if="user.deletion_requested_at"
              class="mb-5 bg-warning text-white"
            >
              <div class="card-body d-flex justify-content-between">
                <div>{{ $t("delete_request") }}</div>
                <div>
                  {{
                    $u2d(
                      new Date(user.deletion_requested_at),
                      "DD MMMM YYYY - LT"
                    )
                  }}
                </div>
              </div>
            </mega-card>

            <section class="no-select mb-5">
              <div class="d-flex mb-4">
                <h4 class="bold text-muted text-dark mb-0">
                  {{ $t("activity") }}
                </h4>
              </div>

              <div class="row">
                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <mega-card class="rounded" :loading="!rounds.loaded">
                    <div class="card-body">
                      <h3
                        class="strong text-success mb-0"
                        style="line-height: 1"
                      >
                        {{ rounds.count }}
                      </h3>
                      <span class="text-muted">{{ $t("rounds") }}</span>
                    </div>

                    <div class="card-media rounded-bottom">
                      <mega-chart
                        ratio="10x3"
                        :labels="Object.keys(dataset.rounds)"
                        clear="grid"
                        :offset="10"
                      >
                        <mega-chart-set
                          title="Rounds"
                          :data="Object.values(dataset.rounds)"
                          color="success"
                          fill
                        />
                      </mega-chart>
                    </div>
                  </mega-card>
                </div>

                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <mega-card class="rounded" :loading="!rounds.loaded">
                    <div class="card-body">
                      <h3
                        class="strong text-warning mb-0"
                        style="line-height: 1"
                      >
                        {{ sessions.count }}
                      </h3>
                      <span class="text-muted">{{ $t("sessions") }}</span>
                    </div>

                    <div class="card-media rounded-bottom">
                      <mega-chart
                        ratio="10x3"
                        :labels="Object.keys(dataset.sessions)"
                        clear="grid"
                        :offset="10"
                      >
                        <mega-chart-set
                          title="Sessions"
                          :data="Object.values(dataset.sessions)"
                          color="warning"
                          fill
                        />
                      </mega-chart>
                    </div>
                  </mega-card>
                </div>
                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <chart
                    v-if="user.idt_user"
                    endpoint="/stat/user/roundlikes"
                    chartName="round_likes"
                    :doubleGraph="false"
                    :idt_user_owner="user.idt_user"
                  />
                </div>
                <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                  <chart
                    v-if="user.idt_user"
                    endpoint="/stat/user/roundviews"
                    chartName="round_views"
                    :doubleGraph="false"
                    :idt_user_owner="user.idt_user"
                  />
                </div>
              </div>
            </section>

            <section v-if="user.is_influencer">
              <promoCodes :userData="user" />
            </section>

            <section class="mb-5" v-if="rounds.loaded && rounds.count > 0">
              <div class="d-flex mt-4">
                <h4 class="bold text-muted text-dark">
                  {{ $t("last_rounds") }}
                </h4>
                <router-link
                  class="btn btn-sm ml-auto"
                  :to="{ name: 'User_Rounds', params: { user_id: id } }"
                  >{{ $t("view_all") }}</router-link
                >
              </div>

              <mega-slider class="row" :options="slider">
                <mega-slide v-for="(round, i) in rounds.list" :key="i">
                  <div class="px-3">
                    <round-card class="cursor-grab" :data="round" />
                  </div>
                </mega-slide>
              </mega-slider>
            </section>

            <section class="mb-5" v-if="models.loaded && models.count > 0">
              <div class="d-flex mb-4">
                <h4 class="bold text-muted text-dark mb-0">
                  {{ $t("last_models") }}
                </h4>
                <router-link
                  class="btn btn-sm ml-auto"
                  :to="{
                    name: 'Models',
                    params: { roundType: '3d', is_active: 'all' },
                    query: { user: id }
                  }"
                  >{{ $t("view_all") }}</router-link
                >
              </div>

              <no-ssr>
                <mega-slider class="row" :options="slider">
                  <mega-slide v-for="(model, i) in models.list" :key="i">
                    <div class="px-3">
                      <model-card class="cursor-grab" :data="model" />
                    </div>
                  </mega-slide>
                </mega-slider>
              </no-ssr>
            </section>

            <section v-if="sessions.count">
              <div class="d-flex mb-4">
                <h4 class="bold text-muted text-dark mb-0">
                  {{ $t("user_sessions") }}
                </h4>
              </div>

              <mega-card class="mt-4">
                <div class="card-body">
                  <div
                    class="w-100 navbar-label align-items-center text-dark"
                    :class="{ 'mt-3': i > 0 }"
                    v-for="(session, i) in sessions.list"
                    :key="i"
                  >
                    <span
                      class="mr-3 sticker sticker-sm navbar-avatar bg-light"
                      :class="{ 'bg-success': session.device === 'i-android' }"
                    >
                      <i :class="session.device"></i>
                    </span>

                    <div class="content">
                      <small class="strong">
                        <span>{{ session.datetime }}</span>
                      </small>
                      <small class="text-muted">IP: {{ session.ip }}</small>
                    </div>
                  </div>
                </div>
              </mega-card>
            </section>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import RoundCard from "../Rounds/components/round-card";
import ModelCard from "../Models/components/model-card";
import IconBase from "../../../components/icons/IconBase.vue";
import UserVerified from "../../../components/icons/set/UserVerified.vue";
import QrCode from "../../components/QrCode";
import Chart from "../../components/Chart";
import PromoCodes from "./components/promocodes";

export default {
  name: "UserPage",
  props: ["id"],
  data() {
    return {
      avatar: undefined,
      year: this.$u2d(new Date(), "YYYY"),
      dataset: {
        rounds: this.months(),
        models: this.months(),
        sessions: this.months()
      },
      rounds: {
        count: 0,
        loaded: false,
        list: []
      },
      models: {
        count: 0,
        loaded: false,
        list: []
      },
      slider: {
        slidesPerView: 3,
        breakpoints: {
          750: {
            slidesPerView: 1
          },
          990: {
            slidesPerView: 2
          },
          1100: {
            slidesPerView: 1
          },
          1470: {
            slidesPerView: 2
          }
        }
      },
      user: {
        loaded: false
      }
    };
  },
  computed: {
    sessions() {
      let sessions = {
        list: [],
        loaded: false,
        count: 0
      };

      if (!this.user["sessions"]) return sessions;

      Object.keys(this.dataset.sessions).map(k => {
        this.dataset.sessions[k] = 0;
      });

      this.user["sessions"].map(session => {
        let month = this.$u2d(session["a_time"], "MMM");
        let year = this.$u2d(session["a_time"], "YYYY");

        if (year === this.year) this.dataset.sessions[month]++;

        sessions.list.push({
          ip: session["ip"],
          device: this.device(session["user_agent"]),
          datetime: this.$u2d(session["a_time"], "DD.MM.YYYY - LT")
        });
      });

      sessions.count = this.user["sessions"].length;
      sessions.loaded = true;

      return sessions;
    },
    vipStatus: {
      get() {
        switch (this.user.vip_type) {
          default:
            return 1;
          case "vip":
            return 2;
          case "vip_pro":
            return 3;
        }
      },
      set(value) {
        switch (value) {
          case 1:
            this.$api.v2.delete("/user/vip", {
              params: {
                idt_user: this.user.idt_user
              }
            });
            break;
          case 2:
            this.$api.v2.put("/user/vip", {
              idt_user: this.user.idt_user,
              vip_type: "vip"
            });
            break;
          case 3:
            this.$api.v2.put("/user/vip", {
              idt_user: this.user.idt_user,
              vip_type: "vip_pro"
            });
            break;
        }
      }
    }
  },
  mounted() {
    this.$navbar.name = `${this.$t("user")} #${this.id}`;

    this.getUser();
    this.getRounds();
    this.getModels();
  },
  methods: {
    getUser() {
      this.$api.v1
        .get("/user", { params: { id: this.id } })
        .then(response => {
          this.user = response.data.user;
          this.user.oloaded = true;

          if (this.user["idc_sex"] === 1) this.user.sex = this.$t("sex_type_1");
          if (this.user["idc_sex"] === 2) this.user.sex = this.$t("sex_type_2");
          if (this.user["idc_sex"] === 3) this.user.sex = this.$t("sex_type_3");

          this.$navbar.name = `${this.$t("user")} #${this.id} | ${
            this.user.name
          }`;

          this.$render(this.user["avatar"]).then(src => {
            this.avatar = src;
          });

          //Берем кол-во раундов пользователя из его профиля
          this.rounds.count = this.user.round_count;
        })
        .catch(() => {
          this.$router.replace("/404");
        });
    },

    verifyToggle() {
      const data = {
        idt_user: this.user.idt_user,
        verified: !this.user.verified
      };

      this.$api.v2base
        .put("/user/verify", data)
        .then(() => {
          this.getUser();
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    influencerToggle() {
      this.$api.v2base
        .post("/user/influencers", {
          idt_user: this.user.idt_user,
          value: !this.user.is_influencer
        })
        .then(() => {
          this.user.is_influencer = !this.user.is_influencer;
          this.$alert.success(this.$t("success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    evangelistToggle() {
      this.$api.v2
        .put("/user/evangelist", {
          idt_user: this.user.idt_user,
          value: !this.user.is_evangelist
        })
        .then(() => {
          this.user.is_evangelist = !this.user.is_evangelist;
          this.$alert.success(this.$t("success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    producerToggle() {
      this.$api.v2
        .post("/user/producer", {
          idt_user: this.user.idt_user,
          value: !this.user.is_producer
        })
        .then(() => {
          this.user.is_producer = !this.user.is_producer;
          this.$alert.success(this.$t("success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    getRounds() {
      let params = {
        limit: 500,
        idt_user: this.id,
        is_location_hidden: true,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/rounds", { params })
        .then(response => {
          let rounds = response.data.rounds;
          //this.rounds.count = response.data.count;

          if (rounds.length > 6) rounds.length = 6;
          this.rounds.list = rounds;

          this.rounds.loaded = true;

          Object.keys(this.dataset.rounds).map(k => {
            this.dataset.rounds[k] = 0;
          });

          rounds.map(round => {
            let month = this.$u2d(round["a_time"], "MMM");
            let year = this.$u2d(round["a_time"], "YYYY");

            if (year === this.year) this.dataset.rounds[month]++;
          });
        })
        .catch(() => {
          this.rounds.loaded = true;
        });
    },

    getModels() {
      this.$api.v2
        .get("/models", {
          params: {
            idt_user: this.id,
            limit: 500,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(response => {
          let rounds = response.data.models;
          this.models.count = response.data.count;

          if (rounds.length > 6) rounds.length = 6;
          this.models.list = rounds;

          this.models.loaded = true;

          Object.keys(this.dataset.models).map(k => {
            this.dataset.models[k] = 0;
          });

          rounds.map(round => {
            let month = this.$u2d(round["a_time"], "MMM");
            let year = this.$u2d(round["a_time"], "YYYY");

            if (year === this.year) this.dataset.models[month]++;
          });
        })
        .catch(() => {
          this.models.loaded = true;
        });
    },

    months() {
      let month = new Date().getMonth();
      let arr = {};
      let months = [
        this.$t("date.months.short.jan"),
        this.$t("date.months.short.feb"),
        this.$t("date.months.short.mar"),
        this.$t("date.months.short.apr"),
        this.$t("date.months.short.may"),
        this.$t("date.months.short.jun"),
        this.$t("date.months.short.jul"),
        this.$t("date.months.short.aug"),
        this.$t("date.months.short.sep"),
        this.$t("date.months.short.oct"),
        this.$t("date.months.short.nov"),
        this.$t("date.months.short.dec")
      ];

      months
        .filter((m, i) => i <= month)
        .map(m => {
          arr[m] = 0;
        });

      return arr;
    },

    device(user_agent) {
      let d = undefined;

      let s = user_agent.toLowerCase();

      d = "i-monitor";
      if (s.includes("ios")) d = "i-apple";
      if (s.includes("android")) d = "i-android";

      return d;
    }
  },
  components: {
    RoundCard,
    ModelCard,
    IconBase,
    UserVerified,
    QrCode,
    Chart,
    PromoCodes
  }
};
</script>
