var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "user-page" }, [
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
            [
              _c("mega-card", { staticClass: "card-border card-round" }, [
                _c(
                  "div",
                  { staticClass: "card-media" },
                  [
                    !_vm.avatar
                      ? _c("mega-image", {
                          attrs: { ratio: "1x1", src: _vm.avatar }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.avatar
                      ? _c("img", {
                          staticClass: "w-100",
                          attrs: { src: _vm.avatar, alt: "" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticClass: "nav flex-column font-small text-muted" },
                  [
                    _c("div", { staticClass: "nav-text" }, [
                      _c("i", { staticClass: "i-user-plus" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("u2d")(_vm.user["a_time"], "DD.MM.Y"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "nav-text" }, [
                      _c("i", { staticClass: "i-gift" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.user["birthday_date"] || "- - -"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { "events-disable text-dark": !_vm.user.phone },
                        attrs: { href: "tel:" + _vm.user.phone }
                      },
                      [
                        _c("i", { staticClass: "i-phone" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.user.phone || "- - -"))])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: { "events-disable text-dark": !_vm.user.email },
                        attrs: { href: "mailto:" + _vm.user.email }
                      },
                      [
                        _c("i", { staticClass: "i-mail" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.user.email || "- - -"))])
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("mega-card", [
                _c("ul", { staticClass: "menu-list card-body" }, [
                  _c(
                    "li",
                    { staticClass: "menu-item d-flex flex-column" },
                    [
                      _c("mega-switch", {
                        staticClass: "w-100 bg-success",
                        attrs: {
                          label: _vm.$t("influencer"),
                          checked: _vm.user.is_influencer
                        },
                        on: { click: _vm.influencerToggle }
                      }),
                      _vm._v(" "),
                      _c("mega-switch", {
                        staticClass: "w-100 bg-success",
                        attrs: {
                          label: _vm.$t("evangelist"),
                          checked: _vm.user.is_evangelist
                        },
                        on: { click: _vm.evangelistToggle }
                      }),
                      _vm._v(" "),
                      _c("mega-switch", {
                        staticClass: "w-100 bg-success",
                        attrs: {
                          label: _vm.$t("producer"),
                          checked: _vm.user.is_producer
                        },
                        on: { click: _vm.producerToggle }
                      }),
                      _vm._v(" "),
                      _c("mega-select", {
                        staticClass: "w-100 mb-0",
                        attrs: {
                          label: _vm.$t("vip_status"),
                          placeholder: _vm.$t("vip_status_holder"),
                          options: [
                            { value: 1, name: _vm.$t("no_vip") },
                            { value: 2, name: _vm.$t("vip") },
                            { value: 3, name: _vm.$t("vip_pro") }
                          ]
                        },
                        model: {
                          value: _vm.vipStatus,
                          callback: function($$v) {
                            _vm.vipStatus = $$v
                          },
                          expression: "vipStatus"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("qr-code", { attrs: { uri: "type=prof&id=" + _vm.id } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
            [
              _c("mega-card", { staticClass: "mb-5" }, [
                _c("div", { staticClass: "card-body d-flex flex-column" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-between align-items-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.user.name) +
                              "\n                  "
                          ),
                          _vm.user.verified
                            ? _c(
                                "IconBase",
                                {
                                  attrs: {
                                    viewBox: "0 0 312 311",
                                    iconColor: "#45C1C9"
                                  }
                                },
                                [_c("UserVerified")],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row align-items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mr-3 d-flex flex-row align-items-center"
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("user_verified")) +
                                  "\n                    "
                              ),
                              _c(
                                "IconBase",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    viewBox: "0 0 312 311",
                                    iconColor: "#45C1C9"
                                  }
                                },
                                [_c("UserVerified")],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("mega-switch", {
                            staticClass:
                              "m-0 d-flex flex-row align-items-center",
                            attrs: { checked: _vm.user.verified },
                            on: { click: _vm.verifyToggle }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("sex")) + ":")]),
                    _vm._v(" " + _vm._s(_vm.user["sex"]) + "\n              ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.user.deletion_requested_at
                ? _c(
                    "mega-card",
                    { staticClass: "mb-5 bg-warning text-white" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body d-flex justify-content-between"
                        },
                        [
                          _c("div", [_vm._v(_vm._s(_vm.$t("delete_request")))]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$u2d(
                                    new Date(_vm.user.deletion_requested_at),
                                    "DD MMMM YYYY - LT"
                                  )
                                ) +
                                "\n              "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("section", { staticClass: "no-select mb-5" }, [
                _c("div", { staticClass: "d-flex mb-4" }, [
                  _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("activity")) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _c(
                        "mega-card",
                        {
                          staticClass: "rounded",
                          attrs: { loading: !_vm.rounds.loaded }
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "h3",
                              {
                                staticClass: "strong text-success mb-0",
                                staticStyle: { "line-height": "1" }
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.rounds.count) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(_vm.$t("rounds")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-media rounded-bottom" },
                            [
                              _c(
                                "mega-chart",
                                {
                                  attrs: {
                                    ratio: "10x3",
                                    labels: Object.keys(_vm.dataset.rounds),
                                    clear: "grid",
                                    offset: 10
                                  }
                                },
                                [
                                  _c("mega-chart-set", {
                                    attrs: {
                                      title: "Rounds",
                                      data: Object.values(_vm.dataset.rounds),
                                      color: "success",
                                      fill: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _c(
                        "mega-card",
                        {
                          staticClass: "rounded",
                          attrs: { loading: !_vm.rounds.loaded }
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "h3",
                              {
                                staticClass: "strong text-warning mb-0",
                                staticStyle: { "line-height": "1" }
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.sessions.count) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(_vm.$t("sessions")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-media rounded-bottom" },
                            [
                              _c(
                                "mega-chart",
                                {
                                  attrs: {
                                    ratio: "10x3",
                                    labels: Object.keys(_vm.dataset.sessions),
                                    clear: "grid",
                                    offset: 10
                                  }
                                },
                                [
                                  _c("mega-chart-set", {
                                    attrs: {
                                      title: "Sessions",
                                      data: Object.values(_vm.dataset.sessions),
                                      color: "warning",
                                      fill: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _vm.user.idt_user
                        ? _c("chart", {
                            attrs: {
                              endpoint: "/stat/user/roundlikes",
                              chartName: "round_likes",
                              doubleGraph: false,
                              idt_user_owner: _vm.user.idt_user
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                    },
                    [
                      _vm.user.idt_user
                        ? _c("chart", {
                            attrs: {
                              endpoint: "/stat/user/roundviews",
                              chartName: "round_views",
                              doubleGraph: false,
                              idt_user_owner: _vm.user.idt_user
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.user.is_influencer
                ? _c(
                    "section",
                    [_c("promoCodes", { attrs: { userData: _vm.user } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.rounds.loaded && _vm.rounds.count > 0
                ? _c(
                    "section",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mt-4" },
                        [
                          _c(
                            "h4",
                            { staticClass: "bold text-muted text-dark" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("last_rounds")) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm ml-auto",
                              attrs: {
                                to: {
                                  name: "User_Rounds",
                                  params: { user_id: _vm.id }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("view_all")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "mega-slider",
                        { staticClass: "row", attrs: { options: _vm.slider } },
                        _vm._l(_vm.rounds.list, function(round, i) {
                          return _c("mega-slide", { key: i }, [
                            _c(
                              "div",
                              { staticClass: "px-3" },
                              [
                                _c("round-card", {
                                  staticClass: "cursor-grab",
                                  attrs: { data: round }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.models.loaded && _vm.models.count > 0
                ? _c(
                    "section",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "h4",
                            { staticClass: "bold text-muted text-dark mb-0" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("last_models")) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm ml-auto",
                              attrs: {
                                to: {
                                  name: "Models",
                                  params: { roundType: "3d", is_active: "all" },
                                  query: { user: _vm.id }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("view_all")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "no-ssr",
                        [
                          _c(
                            "mega-slider",
                            {
                              staticClass: "row",
                              attrs: { options: _vm.slider }
                            },
                            _vm._l(_vm.models.list, function(model, i) {
                              return _c("mega-slide", { key: i }, [
                                _c(
                                  "div",
                                  { staticClass: "px-3" },
                                  [
                                    _c("model-card", {
                                      staticClass: "cursor-grab",
                                      attrs: { data: model }
                                    })
                                  ],
                                  1
                                )
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sessions.count
                ? _c(
                    "section",
                    [
                      _c("div", { staticClass: "d-flex mb-4" }, [
                        _c(
                          "h4",
                          { staticClass: "bold text-muted text-dark mb-0" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("user_sessions")) +
                                "\n              "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("mega-card", { staticClass: "mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          _vm._l(_vm.sessions.list, function(session, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass:
                                  "w-100 navbar-label align-items-center text-dark",
                                class: { "mt-3": i > 0 }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mr-3 sticker sticker-sm navbar-avatar bg-light",
                                    class: {
                                      "bg-success":
                                        session.device === "i-android"
                                    }
                                  },
                                  [_c("i", { class: session.device })]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _c("small", { staticClass: "strong" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(session.datetime))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v("IP: " + _vm._s(session.ip))
                                  ])
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }